/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Actions - Setting
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */


import {
    DARK_THEME,
    SWITCH_LANGUAGE
} from 'constants/settings';


export function setDarkTheme() {
    return { type: DARK_THEME };
}

export function switchLanguage(locale) {
    return {
        type: SWITCH_LANGUAGE,
        payload: locale
    };
}