import * as Sentry from '@sentry/browser';
import { ENVIRONMENTS_FE } from "constants/settings";
import packageJson from '../../../package.json';

/**
 * SentryInit
 * @constructor
 */
export const SentryInit = () => {
    if (process.env.NODE_ENV === 'production') {
        Sentry.init(
            {
                dsn: process.env.REACT_APP_SENTRY_DNS,
                environment: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SENTRY_ENVIROMENT : 'development',
                release: packageJson.name + '@' + packageJson.version,
                integrations: [
                    new Sentry.Integrations.Breadcrumbs({
                    console: process.env.REACT_APP_SENTRY_ENVIROMENT === ENVIRONMENTS_FE.DEV
                })],
                normalizeDepth: 5, //6 is too much information to send
                beforeSend(event, hint) {
                    let title = '';
                    let subtitle = '';
                    let contextual_information = '';
                    // Helper function to add URL to contextual information
                    const appendUrlToContext = () => {
                        if (event?.request && event?.request?.url) {
                            contextual_information += ` on url:${event.request.url}`; // Add URL from event
                        } else if (window && window.location) {
                            contextual_information += ` at href:${window.location.href}`; // Fallback to current URL
                        }
                    };
                    function formatFrame(frame) {
                        // Extract information from a single frame (function, filename, lineno, module)
                        let frameInfo = `at ${frame?.filename}:${frame?.lineno}`;
                        if (frame?.function) {
                            frameInfo = `${frame.function} (${frame.filename}:${frame.lineno})`;
                        }
                        if (frame?.module) {
                            frameInfo = `${frame.module}.${frameInfo}`;
                        }
                        return frameInfo;
                    }
                    
                    function getCulpritFromFrames(frames) {
                        // Function to find the first non-internal frame (avoid internal SDK frames)
                        const findRelevantFrame = (frames) => {
                            // Function to find the first non-internal frame (avoid internal SDK/React/Next.js frames)
                            const internalKeywords = [
                                'sentry',
                                'node_modules',
                                'react',
                                'react-dom',
                                'next',
                                'webpack',
                                '_app.js',
                                '_document.js',
                                'logger.js',
                                'error.js',
                                'node:',
                            ];
                            for (let i = frames?.length - 1; i >= 0; i--) {
                                const frame = frames?.[i];
                                // Skip frames that contain internal keywords (Sentry, React, Next.js, etc.)
                                const isInternalFrame = internalKeywords.some((keyword) => frame?.filename?.includes(keyword));
                                if (!isInternalFrame) {
                                    return frame;
                                }
                            }
                            return frames?.[frames?.length - 1]; // Fallback to the last frame if none found
                        };
    
                        // Get the first frame (origin) and the most relevant non-internal frame
                        const firstFrame = frames?.[0]; // First frame (origin)
                        const lastFrame = frames?.[frames?.length - 1]; // Last frame (most recent)
                        const relevantFrame = findRelevantFrame(frames); // Most recent relevant frame
    
                        // Format first, relevant, and last frame information
                        const firstFrameInfo = formatFrame(firstFrame);
                        const relevantFrameInfo = formatFrame(relevantFrame);
                        const lastFrameInfo = formatFrame(lastFrame);
    
                        // Generate contextual information string combining all three frames
                        return `First Frame: ${firstFrameInfo}, Relevant Frame: ${relevantFrameInfo}, Last Frame: ${lastFrameInfo}`;
                    }

                    // If the event contains an exception (most error cases)
                    //@ts-ignore
                    if (event?.exception && event?.exception?.values?.length > 0) {
                        //@ts-ignore
                        const exception = event?.exception?.values?.[event?.exception?.values?.length - 1] ?? {};
                        // 1. Set the title (error type)
                        title = exception?.type || 'EFZ Title Error';
                        // 2. Set the subtitle (error message)
                        subtitle = exception?.value || 'EFZ Subtitle Something went wrong';
                        // 3. Set the contextual information (first frame of the stack trace)
                        //@ts-ignore
                        if (exception?.stacktrace && exception?.stacktrace?.frames?.length > 0) {
                            contextual_information = getCulpritFromFrames(exception?.stacktrace?.frames);
                        }
                        appendUrlToContext();
                        // Modify the exception with the new title and subtitle
                        exception.type = title; // Title (error type)
                        exception.value = subtitle; // Subtitle (error message)
                    } else if (event?.message) {
                        // If it's a message (non-exception event)
                        title = 'EFZ Title Message';
                        subtitle = event?.message ?? null;
                        contextual_information = 'EFZ Contextual Info No stack trace available';
                        appendUrlToContext();
                    }
                    // filter out UnhandledRejection errors that have no information
                    if (
                        event !== undefined &&
                        event.exception !== undefined &&
                        event.exception.values !== undefined &&
                        event.exception.values.length === 1
                    ) {
                        const e = event.exception.values[0];
                        if (e.type === 'UnhandledRejection' && e.value === 'Non-Error promise rejection captured with value:') {
                            event.level = 'warning';
                        }
                    }
    
                    event.tags = {
                        ...event.tags,
                        is_false_positive: isFalsePositive(event, hint, contextual_information),
                    };
                    // Add error message to extras
                    event.extra = {
                        ...event.extra,
                        evMsg: event?.message,
                        evExVal: event?.exception?.values?.[0]?.value,
                        hintOriMsg: hint?.originalException?.message,
                        hintSyntMsg: hint?.syntheticException?.message,
                        title: title,
                        subtitle: subtitle,
                        contextual_information: contextual_information,
                    };
                    return event;
                }
            }
        );
    }
};

const FALSE_POSITIVE_REGEX_ENTRIES = [
    /Could not find a 3d context/,
    /InvalidValueError/,
    /undefined is not an object \(evaluating 'a\.L'\)/,
    /undefined is not an object \(evaluating 'a\.K'\)/,
    /undefined is not an object \(evaluating 'a\.J'\)/,
    /Objects are not valid as a React child/,
    /this\.Kg is not a function/,
    /a\.onContextLost is not a function/,
    /Failed to execute 'insertBefore' on 'Node'/,
    /Illegal invocation TypeError \/js\/widget\.js Object\.postMessage/,
    // eslint-disable-next-line no-useless-escape
    /Error sending request: Failed to fetch RestError .+\Ta Ta\(main\)/,
    /Failed to fetch TypeError \.\.\/node_modules\/@sentry\/src\/nstrument\.ts \?\(chrome-extension/,
    /Right-hand side of 'instanceof' is not an object/,
    /Cannot read properties of null \(reading 'getAt'\)/,
    /Cannot read properties of null \(reading 'zoom'\)/,
    /JSON\.parse: unexpected character/,
    /Loading CSS chunk/,
    /Error sending request: Failed to fetch RestError .+fetchHttpClient/,
    /No error message RestError .+deserializationPolicy/,
    /Object doesn't support property or method 'forEach'/,
    /a\.stopPropagation is not a function/,
    /_path\(assets\/@efz\)/,
    /Can't find variable: gmo/,
    /maps-api-v3/,
    /maps\/api\/js/,
    /google-maps-react/,
    /react-google-autocomplete/,
    /xbrowser is not defined/,
    /execute_auto_fill(<anonymous>)/
];


const matchesAnyRegex = (string, regexArray) => regexArray?.some((r) => !!string?.match(r));

const isFalsePositive = (event, hint, contextual_information = '') => {
    return (
        matchesAnyRegex(hint?.originalException?.name, FALSE_POSITIVE_REGEX_ENTRIES) ||
        (!!event?.message && matchesAnyRegex(event?.message, FALSE_POSITIVE_REGEX_ENTRIES)) ||
        (!!event?.exception?.values?.[0]?.value && matchesAnyRegex(event?.exception?.values?.[0]?.value, FALSE_POSITIVE_REGEX_ENTRIES)) ||
        (!!hint?.originalException?.message && matchesAnyRegex(hint?.originalException?.message , FALSE_POSITIVE_REGEX_ENTRIES)) ||
        (!!hint?.syntheticException?.message && matchesAnyRegex(hint?.syntheticException?.message, FALSE_POSITIVE_REGEX_ENTRIES)) ||
        matchesAnyRegex(contextual_information, FALSE_POSITIVE_REGEX_ENTRIES)
    );
};



const SENTRY_LEVEL = {
    0: 'Critical',
    1: 'Fatal',
    2: 'Error',
    3: 'Warning',
    4: 'Info',
    5: 'Debug',
    6: 'Log',
}

/**
 * SentryCaptureException
 * 
 * @param {
 *  level : 3,//Error 
 *  message : 'Something went wrong',
 *  context : { name: 'CacheBusterData',
                data:{  latestVersion: '1.2',
                        currentVersion: '1.0',
                        location: 'facility'
                        }
            }
 *  extrasContext : {facilityID: 123456},
 *  tags : {api: 'failities', component: 'header' },
 *  user : {userType:5, username: 'manager' },
 * } props 
 * 
 */
export function SentryCaptureException(props) {

    const scope = new Sentry.Scope();

    //#region props 
    let level = props?.level ?? 3;
    let message = props?.message ?? 'ALERT Something went wrong';
    let context = props?.context ?? null;
    let extrasContext = props?.extrasContext ?? null;
    let tags = props?.tags ?? null;
    let user = props?.user ?? null;
    //#endregion


    // level
    scope.setLevel(Sentry.Severity[SENTRY_LEVEL[level]]);

    // context
    if (!!context) scope.setContext(context.name, context.data);

    // extrasContext
    if (!!extrasContext) scope.setExtras(extrasContext);

    // tags
    if (!!tags) scope.setTags(tags);

    // user
    if (!!user) scope.setUser(user);

    Sentry.captureMessage(message, scope);

};
