import { AVAILABLE_LOCALES } from './user';

// * DEFAULT OBJECTS * \\

//Local Storage Keys
export const LS_KEYS = {
    WEBSTOMP: {
        MQ_MESSAGE: 'SALES_B2C_MQ_MESSAGE',
    }
};
export const ENVIRONMENTS_FE = {
    DEV: 'DEV',
    TST: 'TST',
    PRD: 'PRD',
};



// *  REDUX STATE * \\
export const INITIAL_SETTINGS = {
    darkTheme: false,
    locale: {
        languageId: 'english',
        locale: AVAILABLE_LOCALES.enGB,
        name: 'label.english',
        icon: 'gb'
    }
};

export const BASENAME_URL = '/';
export const BASENAME_URL_LOGIN = BASENAME_URL + 'login';
export const BASENAME_URL_AUTH_ADFS = BASENAME_URL + 'auth';
export const BASENAME_URL_APP = BASENAME_URL + 'app/';
export const BASENAME_URL_PROCESSES = BASENAME_URL_APP + 'processes';
export const BASENAME_URL_USERS = BASENAME_URL_APP + 'users';
export const BASENAME_URL_USER = BASENAME_URL_APP + 'user';
export const BASENAME_URL_OPPORTUNITIES = BASENAME_URL_APP + 'opportunities';
export const BASENAME_URL_ADFS_TOKEN_EXPIRY = BASENAME_URL + 'adfslogout';
export const BASENAME_URL_COOKIES_POLICIES = BASENAME_URL_APP + 'cookie-policies';



//ACTION
export const THEME_COLOR = 'THEME_COLOR';
export const DARK_THEME = 'DARK_THEME';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

