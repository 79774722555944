/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Validação do routeamento e validação de acesso ao Contanier
 *
 * @version 20191001
 * @since 20191001 Initial release
 *
 */
import { useState } from 'react';
import PropTypes from "prop-types";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useForm, Controller } from 'react-hook-form';

import { ReactComponent as VisibilityIcon } from 'assets/images/icons/svg/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from 'assets/images/icons/svg/visibilityOff.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/svg/arrow-blue.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icons/svg/clear.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/svg/green-check.svg';
import ErrorMessage from 'components/ErrorMessage';
import IntlMessages from 'components/util/IntlMessages';
import { intlMessages, isFieldDefined, PASSWORD_CHECK } from 'services/util/auxiliaryUtils';
import useRecoveryPassword from 'hooks/recoveryPassword';
import Loading from 'components/Loading';
import Alert from 'components/@efz/Alert';



/**
 * @context Route to RedefinePasswordDialog
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const RedefinePasswordDialog = ({
    userSignOut,
    userID
}) => {

    const [redefinePasswordOpen, setRedefinePasswordOpen] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const {
        isRequestingRedefinePwd,
        fetchRedefinePassword
    } = useRecoveryPassword();


    // Form validation
    const {
        handleSubmit,
        control,
        watch,

        formState: {
            errors,
        },
    } = useForm({
        mode: 'onChange'
    });

    const watchNewPassword = watch('newPassword');

    // From input rules validation
    const rules = {
        // currentPassword: {
        //     required: 'yup.message.required',
        // },
        newPassword: {
            required: 'yup.message.required',
            minLength: {
                value: 8,
                message: 'yup.message.string.minCharacters'
            },
            validate: {
                hasLowercase: value => PASSWORD_CHECK.PASSWORD_HAS_LOWERCASE_REGEXP.test(value) || 'yup.message.password.required.lowerCase',
                hasUppercase: value => PASSWORD_CHECK.PASSWORD_HAS_UPPERCASE_REGEXP.test(value) || 'yup.message.password.required.upperCase',
                hasDigit: value => PASSWORD_CHECK.PASSWORD_HAS_DIGIT_REGEXP.test(value) || 'yup.message.password.required.number',
                hasSpecialCharacter: value => PASSWORD_CHECK.PASSWORD_HAS_SPECIAL_CHARACTER.test(value) || 'yup.message.password.required.specialCharacter'
            }
        },
        confirmPassword: {
            required: 'yup.message.required',
            validate: value => value === watchNewPassword || 'yup.message.oneOf.matchPasswords'
        }
    };


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onCloseDialog = (event, reason) => {
        if (reason && reason === 'backdropClick') {
            return;
        }
        setRedefinePasswordOpen(false);
    };


    const onSubmitClickHandler = (payload) => {
        const body = {
            password: payload.newPassword
        }
        fetchRedefinePassword(userSignOut, userID, body, onCloseDialog);
    };

    const alertBullets = (size) => {
        const bullets = [];
        const rule = rules['newPassword'];
        const validationList = [
            (isFieldDefined(watchNewPassword) && watchNewPassword.length >= rule.minLength.value) ?? false,
            (isFieldDefined(watchNewPassword) && typeof (rule.validate.hasDigit(watchNewPassword)) === 'boolean') ?? false,
            (isFieldDefined(watchNewPassword) && typeof (rule.validate.hasUppercase(watchNewPassword)) === 'boolean') ?? false,
            (isFieldDefined(watchNewPassword) && typeof (rule.validate.hasLowercase(watchNewPassword)) === 'boolean') ?? false,
            (isFieldDefined(watchNewPassword) && typeof (rule.validate.hasSpecialCharacter(watchNewPassword)) === 'boolean') ?? false,
        ];

        const iconChooser = (type) => {
            switch (type) {
                case 'error':
                    return <ErrorIcon />;
                case 'success':
                    return <CheckIcon />;
                case 'arrow':
                default:
                    return <ArrowIcon />;
            }
        };

        for (let i = 1; i <= size; i++) {
            bullets.push(
                <div key={`dialog-bullet-${i}`} className={`dialog-bullet ${!isFieldDefined(watchNewPassword) ? '' : (`dialog-bullet-${!validationList[i - 1] ? 'error' : 'success'}`)}`}>
                    <div className='bullet-icon'>
                        {iconChooser(!isFieldDefined(watchNewPassword) ? 'arrow' : (!validationList[i - 1] ? 'error' : 'success'))}
                    </div>
                    <IntlMessages id={`dialog.passwordRedefine.info.part${i}`} />
                </div>
            )
        }
        return bullets;
    };

    const alertContentText = (
        <div className='dialog-bullets'>
            {alertBullets(5)}
        </div>
    );

    return (
        <div className="content-wrapper-fluid banner-home">
            <Dialog
                disableEscapeKeyDown
                open={redefinePasswordOpen}
                onClose={onCloseDialog}
                className="redefine-password-dialog"
            >
                <form onSubmit={handleSubmit(onSubmitClickHandler)}>
                    <DialogTitle className="dialog-title">
                        <IntlMessages id='dialog.passwordRedefine.title' />
                        <hr />
                    </DialogTitle>
                    <DialogContent className="dialog-body">
                        <div className='inputs-container'>

                            { /* currentPassword */}
                            {/* <div className="input-container">
                                <label>
                                    <IntlMessages id="dialog.passwordRedefine.label.currentPassword" /> *
                                </label>
                                <Controller
                                    name="currentPassword"
                                    control={control}
                                    rules={rules['currentPassword']}
                                    render={({ field: { ref, ...field } }) => (
                                        <OutlinedInput
                                            {...field}
                                            defaultValue={''}
                                            placeholder={intlMessages('dialog.passwordRedefine.label.currentPassword')}
                                            type={showPassword ? 'text' : 'password'}
                                            error={!!errors["currentPassword"]}
                                            variant={"outlined"}
                                            disabled={isRequestingRedefinePwd}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                        tabIndex="-1"
                                                    >
                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    )}
                                />
                                {errors["currentPassword"] && <ErrorMessage error={errors["currentPassword"]} values={{ min: rules["currentPassword"].minLength.value }} />}
                            </div>

                            <hr /> */}

                            { /* newPassword */}
                            <div className="input-container">
                                <label>
                                    <IntlMessages id="dialog.passwordRedefine.label.newPassword" /> *
                                </label>
                                <Controller
                                    name="newPassword"
                                    control={control}
                                    rules={rules['newPassword']}
                                    render={({ field: { ref, ...field } }) => (
                                        <OutlinedInput
                                            {...field}
                                            defaultValue={''}
                                            placeholder={intlMessages('dialog.passwordRedefine.label.newPassword')}
                                            type={showPassword ? 'text' : 'password'}
                                            error={!!errors["newPassword"]}
                                            variant={"outlined"}
                                            disabled={isRequestingRedefinePwd}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                        tabIndex="-1"
                                                    >
                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    )}
                                />
                                {errors["newPassword"] && <ErrorMessage error={errors["newPassword"]} values={{ min: rules["newPassword"].minLength.value }} />}
                            </div>

                            { /* confirmPassword */}
                            <div className="input-container">
                                <label>
                                    <IntlMessages id="dialog.passwordRedefine.label.confirmPassword" /> *
                                </label>
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    rules={rules['confirmPassword']}
                                    render={({ field: { ref, ...field } }) => (
                                        <OutlinedInput
                                            {...field}
                                            defaultValue={''}
                                            placeholder={intlMessages('dialog.passwordRedefine.label.confirmPassword')}
                                            variant={"outlined"}
                                            type={showPassword ? 'text' : 'password'}
                                            error={!!errors["confirmPassword"]}
                                            disabled={isRequestingRedefinePwd}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                        tabIndex="-1"
                                                    >
                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    )}
                                />
                                {errors["confirmPassword"] && <ErrorMessage error={errors["confirmPassword"]} />}
                            </div>
                        </div>
                        <Alert
                            severity='info'
                            className="info-text"
                            IntlMessages={<IntlMessages id='dialog.passwordRedefine.info' />}
                            secondaryText={alertContentText}
                        >
                        </Alert>
                    </DialogContent>
                    <DialogActions className="dialog-buttons">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disableRipple
                            disabled={isRequestingRedefinePwd}
                        >
                            <IntlMessages id='dialog.passwordRedefine.button.send' />
                            {isRequestingRedefinePwd && <Loading size={24} className="position-absolute" />}

                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )};

//PropTypes
RedefinePasswordDialog.propTypes = {
    userID: PropTypes.number.isRequired,
    userSignOut: PropTypes.func.isRequired,
};

export default RedefinePasswordDialog;