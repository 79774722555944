import { getBaseURL } from "services/settings";
import { isDefined } from "services/util/auxiliaryUtils";

// Debouncing time - time that a user has to input something else before a request is sent in milliseconds
export const DEBOUNCE_TIME = 300;

/**
 * getStaticFiles
 * @description ficheiros estaticos que encontra-se na AzrStorage
 *
 * @param {*} filename
 */
export function getStaticFiles(filename, dir1 = '', dir2 = '', dir3 = '') {
    return isDefined(filename)
        ? `${getBaseURL()}${process.env.REACT_APP_API_URL_STATIC_FILES}${dir1}${dir2}${dir3}${filename}`
        : undefined;
}

// * ACTION TYPES * \\
export const URL_BASE = '';
export const URL_AUTH = 'auth';
export const URL_AUTH_MODES = 'auth-modes';
export const URL_OPPORTUNITIES = 'efficiency-measures';
export const URL_HOURLY_OPTIONS = 'hourly-options';
export const URL_FIRST_ARRIVALS = 'first-arrivals';
export const URL_CONTRACTED_POWER = 'contracted-power';
export const URL_SIMULATIONS = 'simulations';
export const URL_EQUIPMENT = 'equipment';
export const URL_EQUIPMENT_UTILIZATION_FREQUENCY = 'equipment-utilization-frequency';
export const URL_EQUIPMENT_SEASON = 'equipment-season';
export const URL_EQUIPMENT_HOURLY_PERIOD = 'equipment-hourly-period';
export const URL_EQUIPMENT_HOURLY_PERIOD_MEAL = 'equipment-hourly-period-meal';
export const URL_WEEKEND_IN_HOUSE = 'weekend-in-house';
export const URL_WORK_DAYS_OCCUPATION_PERIOD = 'work-days-occupation-period';
export const URL_SIMULATION_STATUS = 'simulation-status';
export const URL_PRODUCT = 'product';
export const URL_CLIENT = 'client';
export const URL_USER = 'user';
export const URL_USER_ACCOUNT = 'user-account';
export const URL_USER_DETAILS = 'user-details';
export const URL_USER_TYPE = 'user-type';
export const URL_USER_GROUP = 'user-group';
export const URL_SALES_TEAM = 'sales-team';
export const URL_CHANNELS = 'channels';
export const URL_PARTNERS = 'partners';
export const URL_USER_MANAGEMENT_PARTNERS = 'user-management-partners';
export const URL_SUPERVISORS = 'supervisors';
export const URL_SALES_POINT = 'sales-point';
export const URL_USER_MANAGEMENT_SALES_POINT = 'user-management-sales-point';
export const URL_IMG_CONTRACT = 'img-contract';
export const URL_LEADS = 'leads';
export const URL_LOGOUT = 'auth/adfs/logout';
export const URL_EV_MODELS = 'ev-models';
export const URL_ELECTRIC_INSTALLATION_TYPE = 'electric-installation-type';
export const URL_DISTANCE_RANGES = 'distance-ranges';
export const URL_INSTALLATION_CONNECTION = 'installation-connection';
export const URL_CPES = 'cpes';
export const URL_DISTANCE_PARKING_SPOTS = 'distance-parking-spots';
export const URL_DISTANCE_PARKING_SPOTS_QP = 'distance-parking-spots-qp';
export const URL_DISCOUNT = 'discount-code';
export const URL_SOLARPV = "solarpv";
export const URL_ME_INDIVIDUAL = "me-individual";
export const URL_ME_CONDOMINIUM = "me-condominium";
export const URL_OLD_EQUIPMENT_TYPE = 'old-equipment-type';
export const URL_DAILY_COOKING_TIME = 'daily-cooking-time';
export const URL_ENERGY_SOURCE = 'energy-source';
export const URL_HOUSE_TYPE = 'house-type';
export const URL_NUM_OCCUPANTS = 'num-occupants';
export const URL_KITCHEN_EQUIPMENT = 'kitchen-equipment';
export const URL_KITCHEN_EQUIPMENT_ENERGY_SOURCE = 'kitchen-equipment-energy-source';
export const URL_EQUIPMENT_DAILY_PERIOD = 'equipment-daily-period';
export const URL_GAS_MONTHLY_INVOICE = 'gas-monthly-invoice';
export const URL_TANK_TYPE = 'tank-type';
export const URL_USAGE_PERIOD = 'usage-period';
export const URL_DISTRICT_CONDITIONS = 'district-conditions';
export const URL_ROOM_TYPE = 'room-type';
export const URL_ELECTRICAL_HOUSE = 'electrical-house';
export const URL_BOX_CONNECTION_TYPE = 'box-connection-type';
export const URL_BALCONY_LENGTH = 'balcony-length';
export const URL_AUDIT_FILES = 'audit-files';
export const URL_NIF = 'nif';
export const URL_ELECTRICAL_CHAIN = 'electrical-chain';
export const URL_BUSINESS_MODELS = 'business-models';
export const URL_SPACE_TYPE = 'space-type';
export const URL_REPORTING = 'reporting';
export const URL_CHARGING_POWER = 'charging-power';
export const URL_INSTALLATION_SITE = 'installation-site';
export const URL_MAIN_ELECTRICAL_PANEL = 'main-electrical-panel';
export const URL_NETWORK_CONNECTION = 'network-connection';
export const URL_NETWORK_CONNECTION_TYPES = 'network-connection/types';
export const URL_PROPERTY_OWNER_TYPE = 'property-owner-type';

export const CONFIG_HEADERS = {
    'Content-Type': 'application/json; charset=utf-8',
    cancellable: true,
    timeout: 90000//1min5seg
};