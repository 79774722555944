import { USER_GROUP_IDS } from "constants/user";
import { ReactComponent as SolarPVIcon } from "assets/images/icons/svg/products/solarPV-icon.svg";
import { ReactComponent as AQSIcon } from "assets/images/icons/svg/products/AQS-icon.svg";
import validate, { VALIDATION_RULES } from 'services/util/validate';
import { FORM_INPUTS_TYPE } from "constants/forms";
import IntlMessages from "components/util/IntlMessages";
import { intlMessages } from "services/util/auxiliaryUtils";

export const PRIVACY_POLITICS_LINK = 'https://www.edp.pt/politica-de-privacidade/';

const rangeOfYears = (start, end) => Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index)
    .reverse();

// years list: from current year until 2010
const YEARS = rangeOfYears(2010, new Date().getFullYear());

const MONTHS = [
    { id: 1, label: 'label.january' },
    { id: 2, label: 'label.february' },
    { id: 3, label: 'label.march' },
    { id: 4, label: 'label.april' },
    { id: 5, label: 'label.may' },
    { id: 6, label: 'label.june' },
    { id: 7, label: 'label.july' },
    { id: 8, label: 'label.august' },
    { id: 9, label: 'label.september' },
    { id: 10, label: 'label.october' },
    { id: 11, label: 'label.november' },
    { id: 12, label: 'label.december' },
]
export const SPV_INPUTS_DEFAULTS = {
    space_type_id: '',
    geolocation: {                      //step1_space
        lat: 40.2033145,
        lng: -8.4102573,//<-- coordenate Coimbra
        locality: null,
        region: null,
        address: null,
        postal_code: null,
        polygon: []
    },
    business_model_id: '',
    address: '',
    entry: '',
    apartment_number: '',               //step1_space
    additional_address_fields: '',      //step1_space
    available_area: 0,                  //step1_space
    social_neighborhood_id: '',         //step1_space
    balcony_length_id: '',              //step2_consumption
    nif: '',                            //step2_consumption
    data_source_id: '',                 //step2_consumption
    invoice_value: '',                  //step2_consumption
    hourly_option_id: '',               //step2_consumption
    contracted_power_id: '',            //step2_consumption
    work_days_occupied: '',             //step2_consumption
    work_days_occupation_period_id: '', //step2_consumption
    work_days_first_arrivals_id: '',    //step2_consumption
    weekend_occupation_id: '',          //step2_consumption
    common_spaces_number: null,         //step2_consumption
    common_spaces: [],                  //step2_consumption
    joint_owners_number: '',            //step2_consumption
    joint_owners_smart_meter: '',       //step2_consumption
    joint_owners_info: [],              //step2_consumption
    equipments: [],                     //step3_equipaments
    range_id: null,                     //step4 (1/3) - escolher solução
    solution: null,                     //step4 (1/3) - escolher solução
    aqs_selected: null,                 //step4 (1/3) - escolher aqs bundle
    num_occupants: null,                //step4 (1/3) - escolher aqs bundle
    old_equipment_type_id: null,        //step4 (1/3) - escolher aqs bundle
    energy_source_id: null,             //step4 (1/3) - escolher aqs bundle   
    business_model_id_before_aqs: null, //step4 (1/3) - escolher aqs bundle 
    contact_details: null               //step4 (2/3) - dados finais
}

export const DEFAULTS_PROPS_MAP = {
    zoomIn: 19,
    mapOptions: {
        id: 'map',
        zoom: 7,//start
        tilt: 0,
        mapType: 'satellite',
        streetViewControl: false,
        rotateControl: true,
        disableDefaultUI: true,
        mapTypeControl: false,
        fullscreenControl: false,
        mapTypeId: 'hybrid',
    },
    settings: {
        apiKey: process.env.REACT_APP_GMAP_API_KEY,
        libraries: ["places", "drawing", "geometry"],
        version: "3.30",
    },
    polygon: {
        styles: {
            strokeOpacity: 0.9,
            strokeWeight: 2,
            fillOpacity: 0.5,
            strokeColor: {
                selected: "rgba(245, 234, 143, 1)",
                notSelected: "rgba(72, 68, 71, 1)"
            },
            fillColor: {
                selected: "rgba(214, 194, 20, 1)",
                notSelected: "rgba(251, 247, 208, 1)"
            }
        }
    }
};

export const TYPOLOGY_IDS = {
    INDIVIDUAL: 1,
    COMMUNITIES: 2,
}

export const SPACE_TYPE_IDS = {
    HOUSING: 1,
    CONDOMINIUM: 2,
    NEIGHBOUR: 3,
    HOUSING_PANELS: 4,
    APARTMENT: 5
}

export const LABEL_SPACE_TYPE_IDS = {
    [SPACE_TYPE_IDS.HOUSING]: 'housing',
    [SPACE_TYPE_IDS.CONDOMINIUM]: 'condominum',
    [SPACE_TYPE_IDS.NEIGHBOUR]: 'neighbor',
    [SPACE_TYPE_IDS.HOUSING_PANELS]: 'housingWithPanels',
    [SPACE_TYPE_IDS.APARTMENT]: 'apartment'
}

export const SPV_STEPS = {
    SPACE: '1',
    CONSUMPTION: '2',
    EQUIPMENTS: '3',
    SIMULATION: '4'
}

export const SIMULATION_STEP = {
    CARDFORM: 1,
    CONTACTFORM: 2,
    CONFIRMATION: 3,
}

export const SPV_INPUTS_STEPS = [
    { label: "label.space", iconLabel: SPV_STEPS.SPACE, order: 1 },
    { label: "label.consumption", iconLabel: SPV_STEPS.CONSUMPTION, order: 2 },
    { label: "label.equipments", iconLabel: SPV_STEPS.EQUIPMENTS, order: 3 },
    { label: "label.simulation", iconLabel: SPV_STEPS.SIMULATION, order: 4 },
];

export const SPV_INPUTS_STEPS_WO_EQUIP = [
    { label: "label.space", iconLabel: SPV_STEPS.SPACE, order: 1 },
    { label: "label.consumption", iconLabel: SPV_STEPS.CONSUMPTION, order: 2 },
    { label: "label.simulation", iconLabel: SPV_STEPS.EQUIPMENTS, order: 3 },
];

export const SPV_INPUTS_STEPS_ONLY_SIM = [
    { label: "label.space", iconLabel: SPV_STEPS.SPACE, order: 1 },
    { label: "label.simulation", iconLabel: SPV_STEPS.CONSUMPTION, order: 2 },
];

export const SPV_NIF_VALIDATION_STATUS = {
    SUCCESS: 'Success',
    FAILURE: 'Failure',
}

export const SPV_INPUT_NAMES = {
    SPACE_TYPE_ID: 'space_type_id',
    GEOLOCATION: 'geolocation',
    AVAILABLE_AREA: 'available_area',
    INVOICE_VALUE: 'invoice_value',
    HOURLY_OPTION_ID: 'hourly_option_id',
    CONTRACTED_POWER_ID: 'contracted_power_id',
    WORK_DAYS_OCCUPIED: 'work_days_occupied',
    WORK_DAYS_OCCUPATION_PERIOD_ID: 'work_days_occupation_period_id',
    WORK_DAYS_FIRST_ARRIVALS_ID: 'work_days_first_arrivals_id',
    WEEKEND_OCCUPATION_ID: 'weekend_occupation_id',
    EQUIPMENTS: 'equipments',
    RANGE_ID: 'range_id',
    SOLUTION: 'solution',
    CONTACT_DETAILS: 'contact_details',
    CONTACT_NAME: "name",
    CONTACT_TAX_NUMBER: "tax_number",
    CONTACT_EMAIL: "email",
    CONTACT_PHONE_NUMBER: "phone_number",
    CONTACT_IDENTITY_NUMBER: "identity_number",
    CONTACT_ADDRESS_RESIDENTIAL: "address_residential",
    CONTACT_NEIGHBORHOOD_ADDRESS: "neighborhood_address",
    ELECTRIC_INSTALLATION_TYPE_ID: "electric_installation_type_id",
    PERSONAL_DATA_YIELD: 'personal_data_yield',
    REDIRECT_LINK: 'redirect_link',//optional
    IMG_CONTRACT_NAME: 'img_contract_name',
    BUSINESS_MODEL_ID: 'business_model_id',//out inputs
    SOCIAL_NEIGHBORHOOD_ID: 'social_neighborhood_id',
    ADDRESS: 'address',
    COMMON_SPACES_NUMBER: 'common_spaces_number',
    COMMON_SPACES: 'common_spaces',
    JOINT_OWNERS_NUMBER: 'joint_owners_number',
    JOINT_OWNERS_SMART_METER: 'joint_owners_smart_meter',
    JOINT_OWNERS_INFO: 'joint_owners_info',
    SOLUTION_PANELS_NUMBER: 'solution_panels_number',
    DATA_SOURCE_ID: 'data_source_id',
    NIF: 'nif',
    NIF_DATA: 'nif_data',
    SHOW_FORM: 'show_form',
    HAS_VALID_NIF: 'has_valid_nif',
    HOUSE_INDEX: 'house_index',
    // Current Panels
    CPE: 'cpe',
    N_INSTALLED_PANELS: 'n_installed_panels',
    PANELS_NUMBER: 'panels_number',
    INSTALATION_YEAR: 'year',
    INSTALATION_MONTH: 'month',
    ACTUAL_INVERTER: 'actual_inverter',
    ACTUAL_INVERTER_POWER: 'actual_inverter_power',
    TOTAL_INSTALLED_POWER_WP: 'total_installed_power_wp',
    HAS_CENTRAL_INVERTER: 'has_central_inverter',
    IS_HYBRID: 'is_hybrid',
    NOMINAL_POWER_KWN: "nominal_power_kwn",
    // AQS Bundle
    AQS_SELECTED: "aqs_selected",
    BUSINESS_MODEL_ID_BEFORE_AQS: "business_model_id_before_aqs",
    // AQS Questions
    NUM_OCCUPANTS: "num_occupants",
    HAS_WATER_HEATING_EQUIPMENT: "has_water_heating_equipment",
    OLD_EQUIPMENT_TYPE_ID: "old_equipment_type_id",
    ENERGY_SOURCE_ID: "energy_source_id",
    // AQS Housing with panels
    HAS_INSTALLED_BATTERY: "has_installed_battery",
    // Apartments
    APARTMENT_NUMBER: "apartment_number",
    ENTRY: "entry",
    ADDITIONAL_ADDRESS_FIELDS: "additional_address_fields",
    BALCONY_LENGTH_ID: "balcony_length_id",
    REQ_ID_ARR: "req_id_arr",
    STEP_ARR: "step_arr",
    PANEL_ID: "panel_id",
    SOLUTION_ID: "solution_id",
};

export const SPV_EQUIPMENTS_IDS = {
    //general
    FRIDGE: 1,
    WASHING_MACHINE: 2,
    DISHWASHER_MACHINE: 3,
    TUMBLE_DRYER: 4,
    ELECTRIC_OVEN: 5,
    ELECTRIC_PLATE: 6,
    AIR_CONDITIONING: 7,
    ELECTRIC_HEATER: 8,
    WATER_HEATER: 9,
    POOL_PUMP: 10,
    ELECTRIC_CAR_CHARGER: 11,
};

export const SPV_EQUIPMENTS_NAMES = {
    //general
    FRIDGE: "fridge",
    WASHING_MACHINE: "washing_machine",
    DISHWASHER_MACHINE: "dishwasher_machine",
    TUMBLE_DRYER: "tumble_dryer",
    ELECTRIC_OVEN: "electric_oven",
    ELECTRIC_PLATE: "electric_plate",
    AIR_CONDITIONING: "air_conditioning",
    ELECTRIC_HEATER: "electric_heater",
    WATER_HEATER: "water_heater",
    POOL_PUMP: "pool_pump",
    ELECTRIC_CAR_CHARGER: "electric_car_charger",
    //Specifc
    FRIDGE_QUANTITY: "fridge_quantity",
    WASHING_MACHINE_FREQUENCY: "washing_machine_frequency",
    WASHING_MACHINE_PERIOD: "washing_machine_period",
    DISHWASHER_MACHINE_FREQUENCY: "dishwasher_machine_frequency",
    DISHWASHER_MACHINE_PERIOD: "dishwasher_machine_period",
    TUMBLE_DRYER_FREQUENCY: "tumble_dryer_frequency",
    TUMBLE_DRYER_PERIOD: "tumble_dryer_period",
    ELECTRIC_OVEN_FREQUENCY: "electric_oven_frequency",
    ELECTRIC_OVEN_PERIOD_MEAL: "electric_oven_period_meal",
    ELECTRIC_PLATE_FREQUENCY: "electric_plate_frequency",
    ELECTRIC_PLATE_PERIOD_MEAL: "electric_plate_period_meal",
    AIR_CONDITIONING_FREQUENCY: "air_conditioning_frequency",
    AIR_CONDITIONING_PERIOD: "air_conditioning_period",
    AIR_CONDITIONING_SEASON: "air_conditioning_season",
    ELECTRIC_HEATER_FREQUENCY: "electric_heater_frequency",
    ELECTRIC_HEATER_PERIOD: "electric_heater_period",
    WATER_HEATER_PERIOD: "water_heater_period",
    POOL_PUMP_PERIOD: "pool_pump_period",
    POOL_PUMP_SEASON: "pool_pump_season",
    ELECTRIC_CAR_CHARGER_FREQUENCY: "electric_car_charger_frequency",
    ELECTRIC_CAR_CHARGER_PERIOD: "electric_car_charger_period"
}

export const HOUSING_PANELS_INPUTS_DEFAULT = {
    [SPV_INPUT_NAMES.N_INSTALLED_PANELS]: null,
    [SPV_INPUT_NAMES.INSTALATION_YEAR]: null,
    [SPV_INPUT_NAMES.INSTALATION_MONTH]: null,
    [SPV_INPUT_NAMES.ACTUAL_INVERTER]: null,
    [SPV_INPUT_NAMES.ACTUAL_INVERTER_POWER]: null,
    [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: null,
    [SPV_INPUT_NAMES.TOTAL_INSTALLED_POWER_WP]: null,
    [SPV_INPUT_NAMES.NOMINAL_POWER_KWN]: null,
    [SPV_INPUT_NAMES.HAS_INSTALLED_BATTERY]: false
}

export const CPE_DATA_SOURCE_IDS = {
    userData: 1,
    externalData: 2,
};


//#region QUESTIONS
export const SPV_QUESTIONS_STEP1 = {
    [SPV_INPUT_NAMES.SOCIAL_NEIGHBORHOOD_ID]: {
        order: 1,
        name: SPV_INPUT_NAMES.SOCIAL_NEIGHBORHOOD_ID,
        label: `page.spv.question.${SPV_INPUT_NAMES.SOCIAL_NEIGHBORHOOD_ID}`,
        tooltip: null,
        placeholder: `page.spv.question.${SPV_INPUT_NAMES.SOCIAL_NEIGHBORHOOD_ID}.placeholder`,
        disabled: false,
        visible: true,
        validation: null
    },
    [SPV_INPUT_NAMES.ADDRESS]: {
        order: 1,
        name: SPV_INPUT_NAMES.ADDRESS,
        label: `page.spv.question.${SPV_INPUT_NAMES.ADDRESS}`,
        tooltip: null,
        placeholder: `page.spv.question.${SPV_INPUT_NAMES.ADDRESS}.placeholder`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_INPUT_NAMES.CPE]: {
        name: SPV_INPUT_NAMES.CPE,
        label: 'label.cpe',
        placeholder: 'label.enterValueHere',
        disabled: false,
        validation: {
            required: "yup.message.required",
            maxLength: {
                value: 20,
                message: 'yup.message.maxLength'
            },

        }
    },
    [SPV_INPUT_NAMES.NIF]: {
        name: SPV_INPUT_NAMES.NIF,
        label: 'label.verifyNIF',
        placeholder: 'label.enterValueHere',
        disabled: false,
        validationNIF: {
            maxLength: {
                value: 9,
                message: 'yup.message.nifLength'
            },
            minLength: {
                value: 9,
                message: 'yup.message.nifLength'
            },
        },
    },
    [SPV_INPUT_NAMES.N_INSTALLED_PANELS]: {
        name: SPV_INPUT_NAMES.N_INSTALLED_PANELS,
        label: 'label.nPanels',
        placeholder: 'label.enterValueHere',
        disabled: false,
        validation: {
            required: "yup.message.required",
            min: {
                value: 1,
                message: 'yup.message.number.min'
            },
        }
    },
    [SPV_INPUT_NAMES.INSTALATION_YEAR]: {
        name: SPV_INPUT_NAMES.INSTALATION_YEAR,
        label: 'label.instalationYear',
        disabled: false,
        validation: {
            required: "yup.message.required",
        },
        options: YEARS
    },
    [SPV_INPUT_NAMES.INSTALATION_MONTH]: {
        name: SPV_INPUT_NAMES.INSTALATION_MONTH,
        label: 'label.instalationMonth',
        disabled: false,
        validation: {
            required: "yup.message.required",
        },
        options: MONTHS
    },
    [SPV_INPUT_NAMES.HAS_CENTRAL_INVERTER]: {
        name: SPV_INPUT_NAMES.HAS_CENTRAL_INVERTER,
        label: 'label.inverterType',
        disabled: false,
        validation: {
            required: "yup.message.required",
        },
        answers: [
            { value: 'false', description: 'label.microInverter' },
            { value: 'true', description: 'label.centralInverter' },
            { value: 'undefined', description: 'label.dontKnow' },
        ]
    },
    [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: {
        name: SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID,
        label: 'label.instalationType',
        disabled: false,
        validation: {
            required: "yup.message.required",
        },
        answers: [
            { value: 1, description: "label.monophasic" },
            { value: 2, description: "label.threephasic" },
            { value: 3, description: "label.dontKnow" },
        ],
    },
    [SPV_INPUT_NAMES.HAS_INSTALLED_BATTERY]: {
        name: SPV_INPUT_NAMES.HAS_INTALLED_BATTERY,
        label: 'label.hasBattery',
        disabled: false,
        validation: {
            required: "yup.message.required",
        },
        answers: [
            { value: 'true', description: 'label.yes' },
            { value: 'false', description: 'label.no' },
        ]
    },
    [SPV_INPUT_NAMES.APARTMENT_NUMBER]: {
        name: SPV_INPUT_NAMES.APARTMENT_NUMBER,
        label: 'label.apartmentNumber',
        placeholder: 'label.enterValueHere',
        disabled: false,
        validation: null,
        isRequired: false,
    },
    [SPV_INPUT_NAMES.ENTRY]: {
        name: SPV_INPUT_NAMES.ENTRY,
        label: 'label.blockOrEntry',
        placeholder: 'label.enterValueHere',
        disabled: false,
        validation: null,
        isRequired: false,
    },
    [SPV_INPUT_NAMES.ADDITIONAL_ADDRESS_FIELDS]: {
        name: SPV_INPUT_NAMES.ADDITIONAL_ADDRESS_FIELDS,
        label: 'label.additionalAddressFields',
        placeholder: 'label.enterValueHere',
        disabled: false,
        validation: null,
        isRequired: false
    },
};

export const SPV_QUESTIONS_STEP2 = {
    [SPV_INPUT_NAMES.NIF]: {
        order: 1,
        name: SPV_INPUT_NAMES.NIF,
        label: `page.spv.question.${SPV_INPUT_NAMES.NIF}`,
        tooltip: `page.spv.question.${SPV_INPUT_NAMES.NIF}.tooltip`,
        placeholder: `page.spv.question.${SPV_INPUT_NAMES.NIF}.placeholder`,
        disabled: false,
        visible: true,
        validation: {
            /* required: "yup.message.required", */
            maxLength: {
                value: 9,
                message: 'yup.message.nifLength'
            },
            minLength: {
                value: 9,
                message: 'yup.message.nifLength'
            },
        },
    },
    [SPV_INPUT_NAMES.INVOICE_VALUE]: {
        order: 2,
        name: SPV_INPUT_NAMES.INVOICE_VALUE,
        label: `page.spv.question.${SPV_INPUT_NAMES.INVOICE_VALUE}`,
        tooltip: `page.spv.question.${SPV_INPUT_NAMES.INVOICE_VALUE}.tooltip`,
        placeholder: `page.spv.question.${SPV_INPUT_NAMES.INVOICE_VALUE}.placeholder`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_INPUT_NAMES.HOURLY_OPTION_ID]: {
        order: 3,
        name: SPV_INPUT_NAMES.HOURLY_OPTION_ID,
        label: `page.spv.question.${SPV_INPUT_NAMES.HOURLY_OPTION_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_INPUT_NAMES.CONTRACTED_POWER_ID]: {
        order: 4,
        name: SPV_INPUT_NAMES.CONTRACTED_POWER_ID,
        label: `page.spv.question.${SPV_INPUT_NAMES.CONTRACTED_POWER_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: {
        order: 5,
        text: `page.spv.final.${SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID}`,
        label: `label.instalationType`,
        name: SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID,
        disabled: false,
        answers: [
            { value: "1", description: "label.monophasic" },
            { value: "2", description: "label.threephasic" },
            { value: "3", description: "label.dontKnow" },
        ],
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_INPUT_NAMES.WORK_DAYS_OCCUPIED]: {
        order: 6,
        name: SPV_INPUT_NAMES.WORK_DAYS_OCCUPIED,
        label: `page.spv.question.${SPV_INPUT_NAMES.WORK_DAYS_OCCUPIED}`,
        disabled: false,
        answers:  [{ label: intlMessages('label.yes'), value: true }, { label: intlMessages('label.no'), value: false }],
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID]: {
        order: 7,
        name: SPV_INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID,
        label: `page.spv.question.${SPV_INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID}`,
        tooltip: `page.spv.question.${SPV_INPUT_NAMES.WORK_DAYS_OCCUPATION_PERIOD_ID}.tooltip`,
        disabled: false,
        answers: null,
        visible: false,
        validation: { required: "yup.message.required" }
    },
    [SPV_INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID]: {
        order: 8,
        name: SPV_INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID,
        label: `page.spv.question.${SPV_INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID}`,
        tooltip: `page.spv.question.${SPV_INPUT_NAMES.WORK_DAYS_FIRST_ARRIVALS_ID}.tooltip`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_INPUT_NAMES.WEEKEND_OCCUPATION_ID]: {
        order: 9,
        name: SPV_INPUT_NAMES.WEEKEND_OCCUPATION_ID,
        label: `page.spv.question.${SPV_INPUT_NAMES.WEEKEND_OCCUPATION_ID}`,
        disabled: false,
        answers: null,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_INPUT_NAMES.BALCONY_LENGTH_ID]: {
        name: SPV_INPUT_NAMES.BALCONY_LENGTH_ID,
        label: 'label.balconyLength',
        disabled: false,
        validation: {
            required: "yup.message.required",
        },
        visible: true,
    },
    [SPV_INPUT_NAMES.HOUSE_INDEX]: {
        name: SPV_INPUT_NAMES.HOUSE_INDEX,
        label: 'label.houseIndex',
        disabled: false,
        validation: {
            required: "yup.message.required",
        },
        visible: true,
    },
    [SPV_INPUT_NAMES.NUM_OCCUPANTS]: {
        //order: 10,
        name: SPV_INPUT_NAMES.NUM_OCCUPANTS,
        label: "page.spv.simulation.bundle-questions.num_occupants",
        tooltip: "page.spv.simulation.bundle-questions.num_occupants.tooltip",
        visible: false,
        disabled: false,
        rules: validate([VALIDATION_RULES.REQUIRED, VALIDATION_RULES.MAX], { max: 10 })
    },
}

export const SPV_QUESTIONS_STEP3 = {
    /* FRIDGE */
    [SPV_EQUIPMENTS_NAMES.FRIDGE]: {
        order: 1,
        name: SPV_EQUIPMENTS_NAMES.FRIDGE,
        label: `label.${SPV_EQUIPMENTS_NAMES.FRIDGE.toUpperCase()}`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.FRIDGE_QUANTITY]: {
        order: 1,
        name: SPV_EQUIPMENTS_NAMES.FRIDGE_QUANTITY,
        label: `page.spv.question.${SPV_INPUT_NAMES.EQUIPMENTS}.${SPV_EQUIPMENTS_NAMES.FRIDGE_QUANTITY}`,
        disabled: false,
        visible: false,
        placeholder: `page.spv.question.${SPV_INPUT_NAMES.EQUIPMENTS}.${SPV_EQUIPMENTS_NAMES.FRIDGE_QUANTITY}.placeholder`,
        validation: {
            required: "yup.message.required",
            min: {
                value: 1,
                message: 'yup.message.number.min'
            },
            validate: (v) => {
                return ((parseFloat(v) >= 0) ? true : "yup.message.number.positive")
            },

        }


    },
    /* WASHING MACHINE */
    [SPV_EQUIPMENTS_NAMES.WASHING_MACHINE]: {
        order: 2,
        name: SPV_EQUIPMENTS_NAMES.WASHING_MACHINE,
        label: `label.${SPV_EQUIPMENTS_NAMES.WASHING_MACHINE.toUpperCase()}`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.WASHING_MACHINE_FREQUENCY]: {
        order: 2,
        name: SPV_EQUIPMENTS_NAMES.WASHING_MACHINE_FREQUENCY,
        label: `label.frequency`,
        disabled: false,
        visible: false,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.WASHING_MACHINE_PERIOD]: {
        order: 2,
        name: SPV_EQUIPMENTS_NAMES.WASHING_MACHINE_PERIOD,
        label: `label.period`,
        disabled: false,
        visible: false,
        validation: { required: "yup.message.required" }
    },
    /* DISHWASHER MACHINE */
    [SPV_EQUIPMENTS_NAMES.DISHWASHER_MACHINE]: {
        order: 3,
        name: SPV_EQUIPMENTS_NAMES.DISHWASHER_MACHINE,
        label: `label.${SPV_EQUIPMENTS_NAMES.DISHWASHER_MACHINE.toUpperCase()}`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.DISHWASHER_MACHINE_FREQUENCY]: {
        order: 3,
        name: SPV_EQUIPMENTS_NAMES.DISHWASHER_MACHINE_FREQUENCY,
        label: `label.frequency`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.DISHWASHER_MACHINE_PERIOD]: {
        order: 3,
        name: SPV_EQUIPMENTS_NAMES.DISHWASHER_MACHINE_PERIOD,
        label: `label.period`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    /* TUMBLE DRYER */
    [SPV_EQUIPMENTS_NAMES.TUMBLE_DRYER]: {
        order: 4,
        name: SPV_EQUIPMENTS_NAMES.TUMBLE_DRYER,
        label: `label.${SPV_EQUIPMENTS_NAMES.TUMBLE_DRYER.toUpperCase()}`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.TUMBLE_DRYER_FREQUENCY]: {
        order: 4,
        name: SPV_EQUIPMENTS_NAMES.TUMBLE_DRYER_FREQUENCY,
        label: `label.frequency`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.TUMBLE_DRYER_PERIOD]: {
        order: 4,
        name: SPV_EQUIPMENTS_NAMES.TUMBLE_DRYER_PERIOD,
        label: `label.period`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    /* ELECTRIC OVEN */
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_OVEN]: {
        order: 5,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_OVEN,
        label: `label.${SPV_EQUIPMENTS_NAMES.ELECTRIC_OVEN.toUpperCase()}`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_OVEN_FREQUENCY]: {
        order: 5,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_OVEN_FREQUENCY,
        label: `label.frequency`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_OVEN_PERIOD_MEAL]: {
        order: 5,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_OVEN_PERIOD_MEAL,
        label: `label.period`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    /* ELECTRIC PLATE */
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_PLATE]: {
        order: 6,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_PLATE,
        label: `label.${SPV_EQUIPMENTS_NAMES.ELECTRIC_PLATE.toUpperCase()}`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_PLATE_FREQUENCY]: {
        order: 6,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_PLATE_FREQUENCY,
        label: `label.frequency`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_PLATE_PERIOD_MEAL]: {
        order: 6,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_PLATE_PERIOD_MEAL,
        label: `label.period`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    /* AIR CONDITIONING */
    [SPV_EQUIPMENTS_NAMES.AIR_CONDITIONING]: {
        order: 7,
        name: SPV_EQUIPMENTS_NAMES.AIR_CONDITIONING,
        label: `label.${SPV_EQUIPMENTS_NAMES.AIR_CONDITIONING.toUpperCase()}`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.AIR_CONDITIONING_FREQUENCY]: {
        order: 7,
        name: SPV_EQUIPMENTS_NAMES.AIR_CONDITIONING_FREQUENCY,
        label: `label.frequency`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.AIR_CONDITIONING_PERIOD]: {
        order: 7,
        name: SPV_EQUIPMENTS_NAMES.AIR_CONDITIONING_PERIOD,
        label: `label.period`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.AIR_CONDITIONING_SEASON]: {
        order: 7,
        name: SPV_EQUIPMENTS_NAMES.AIR_CONDITIONING_SEASON,
        label: `label.season`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    /* ELECTRIC HEATER */
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_HEATER]: {
        order: 8,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_HEATER,
        label: `label.${SPV_EQUIPMENTS_NAMES.ELECTRIC_HEATER.toUpperCase()}`,
        tooltip: `page.spv.question.${SPV_EQUIPMENTS_NAMES.ELECTRIC_HEATER}.tooltip`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_HEATER_FREQUENCY]: {
        order: 8,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_HEATER_FREQUENCY,
        label: `label.frequency`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_HEATER_PERIOD]: {
        order: 8,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_HEATER_PERIOD,
        label: `label.period`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    /* WATER HEATER */
    [SPV_EQUIPMENTS_NAMES.WATER_HEATER]: {
        order: 9,
        name: SPV_EQUIPMENTS_NAMES.WATER_HEATER,
        label: `label.${SPV_EQUIPMENTS_NAMES.WATER_HEATER.toUpperCase()}`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.WATER_HEATER_PERIOD]: {
        order: 9,
        name: SPV_EQUIPMENTS_NAMES.WATER_HEATER_PERIOD,
        label: `label.period`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    /* POOL PUMP */
    [SPV_EQUIPMENTS_NAMES.POOL_PUMP]: {
        order: 10,
        name: SPV_EQUIPMENTS_NAMES.POOL_PUMP,
        label: `label.${SPV_EQUIPMENTS_NAMES.POOL_PUMP.toUpperCase()}`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.POOL_PUMP_PERIOD]: {
        order: 10,
        name: SPV_EQUIPMENTS_NAMES.POOL_PUMP_PERIOD,
        label: `label.period`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.POOL_PUMP_SEASON]: {
        order: 10,
        name: SPV_EQUIPMENTS_NAMES.POOL_PUMP_SEASON,
        label: `label.season`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    /* ELECTRIC CAR CHARGER */
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_CAR_CHARGER]: {
        order: 11,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_CAR_CHARGER,
        label: `label.${SPV_EQUIPMENTS_NAMES.ELECTRIC_CAR_CHARGER.toUpperCase()}`,
        tooltip: `page.spv.question.${SPV_EQUIPMENTS_NAMES.ELECTRIC_CAR_CHARGER}.tooltip`,
        disabled: false,
        visible: true,
    },
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_CAR_CHARGER_FREQUENCY]: {
        order: 11,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_CAR_CHARGER_FREQUENCY,
        label: `label.frequency`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [SPV_EQUIPMENTS_NAMES.ELECTRIC_CAR_CHARGER_PERIOD]: {
        order: 11,
        name: SPV_EQUIPMENTS_NAMES.ELECTRIC_CAR_CHARGER_PERIOD,
        label: `label.period`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },

    /* WATER HEATER - Only on Housing and Housing panels */
    [SPV_INPUT_NAMES.HAS_WATER_HEATING_EQUIPMENT]: {
        order: 12,
        name: SPV_INPUT_NAMES.HAS_WATER_HEATING_EQUIPMENT,
        label: "page.spv.simulation.bundle-questions.water_heater_equipment",
        visible: true,
        disabled: false,
    },
    [SPV_INPUT_NAMES.OLD_EQUIPMENT_TYPE_ID]: {
        order: 12,
        name: SPV_INPUT_NAMES.OLD_EQUIPMENT_TYPE_ID,
        label: "page.spv.simulation.bundle-questions.old_equipment_type_id",
        visible: true,
        disabled: false,
        validation: { required: "yup.message.required" },
    },
    [SPV_INPUT_NAMES.ENERGY_SOURCE_ID]: {
        order: 12,
        name: SPV_INPUT_NAMES.ENERGY_SOURCE_ID,
        label: "page.spv.simulation.bundle-questions.energy_source_id",
        visible: true,
        disabled: false,
        validation: { required: "yup.message.required" },
    }
}

const SPV_QUESTION_CONTACT_FORM = {
    [SPV_INPUT_NAMES.CONTACT_NAME]: {
        name: SPV_INPUT_NAMES.CONTACT_NAME,
        className: 'mtn',
        visible: true,
        placeholder: 'label.name',
        hasRequiredPlaceholder: true,
        validation: validate(['required',], {}),
    },
    [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: {
        name: SPV_INPUT_NAMES.CONTACT_TAX_NUMBER,
        className: 'mtn',
        visible: true,
        placeholder: 'label.nif',
        hasRequiredPlaceholder: true,
        validation: {
            required: "yup.message.required",
            maxLength: {
                value: 9,
                message: 'yup.message.nifLength'
            },
            minLength: {
                value: 9,
                message: 'yup.message.nifLength'
            },
        },
        type: FORM_INPUTS_TYPE.NUMBER,
        allowNegative: false,
        decimalScale: 0,

    },
    [SPV_INPUT_NAMES.CONTACT_IDENTITY_NUMBER]: {
        name: SPV_INPUT_NAMES.CONTACT_IDENTITY_NUMBER,
        className: 'mtn',
        placeholder: `label.identityNumber`,
        hasRequiredPlaceholder: true,
        visible: true,
        validation: {
            equalValue: 8,
            required: "yup.message.required",
            maxLength: {
                value: 8,
                message: 'yup.message.number.isEqual'
            },
            minLength: {
                value: 8,
                message: 'yup.message.number.isEqual'
            },
        },
    },
    [SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL]: {
        name: SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL,
        className: 'mtn',
        placeholder: `label.addressResidential`,
        hasRequiredPlaceholder: true,
        visible: true,
        validation: {
            required: "yup.message.required"
        },
    },
    [SPV_INPUT_NAMES.CONTACT_NEIGHBORHOOD_ADDRESS]: {
        name: SPV_INPUT_NAMES.CONTACT_NEIGHBORHOOD_ADDRESS,
        className: 'mtn',
        placeholder: `label.SolarNeighborhoodAddress`,
        hasRequiredPlaceholder: true,
        visible: true,
        validation: {
            required: "yup.message.required"
        },
    },
    [SPV_INPUT_NAMES.CONTACT_EMAIL]: {
        name: SPV_INPUT_NAMES.CONTACT_EMAIL,
        className: 'mtn',
        visible: true,
        placeholder: 'label.email',
        hasRequiredPlaceholder: true,
        validation: validate(['required', 'email'], {}),
    },
    [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: {
        name: SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER,
        className: 'mtn',
        visible: true,
        placeholder: 'label.phone',
        hasRequiredPlaceholder: true,
        validation: validate(['required'], {}),
        type: FORM_INPUTS_TYPE.PHONE_NUMBER_PT
    },
    [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: {
        name: SPV_INPUT_NAMES.PERSONAL_DATA_YIELD,
        className: 'mt-0',
        visible: true,
        type: FORM_INPUTS_TYPE.CHECKBOX,
        validation: validate(['required'], {}),
        checkbox_text:
            <p className="text-field">
                <IntlMessages id={`page.spv.final.dataFilled`} whitespaceEnd />
                <a href={PRIVACY_POLITICS_LINK} target="_blank" rel="noopener noreferrer">
                    <IntlMessages id="label.here" />
                </a>.
            </p>
    },
    [SPV_INPUT_NAMES.CPE]: {
        name: SPV_INPUT_NAMES.CPE,
        placeholder: 'label.cpe',
        disabled: false,
        visible: true,
        hasRequiredPlaceholder: true,
        validation: {
            value: 20,
            required: "yup.message.required",
            maxLength: {
                value: 20,
                message: 'yup.message.maxLength'
            },

        }
    },
}
//#endregion QUESTIONS



export const SPV_BUSINESS_MODEL_IDS = {
    SELF_CONSUMPTION_SMART: 1,
    LECS: 2,
    BATTERY: 3,//SELF_CONSUMPTION
    SELF_CONSUMPTION_CONDOMINIUM: 7,
    SOLAR_AQS: 8,
    SELF_CONSUMPTION: 10,
    SELF_CONSUMPTION_APARTMENT: 11,
    BATTERY_APARTMENT: 12,
    BATTERY_HOUSING_PANELS: 13,
    SOLAR_AQS_HOUSING_PANELS: 14,
    SELF_CONSUMPTION_HOUSING_PANELS: 15,
}

//#region COMMON SPACE FORM
export const MAX_COMMON_SPACES = 10;

export const COMMON_SPACE_INPUTS = {
    DESCRIPTION: 'description',
    CONTRACTED_POWER_ID: 'contracted_power_id',
    CONTRACTED_POWER_TARIFF: 'contracted_power_tariff',
    HOURLY_OPTION_ID: 'hourly_option_id',
    OPTION_ID: 'option_id',
    CONTRACTED_ENERGY_TARIFF: 'contracted_energy_tariff',
    MONTHLY_INVOICE_BY_HOURLY_PERIOD: 'monthly_invoice_by_hourly_period',
    ELECTRIC_INSTALLATION_TYPE_ID: 'electric_installation_type_id',
};

export const COMMON_SPACE_QUESTIONS = {
    [COMMON_SPACE_INPUTS.ELECTRIC_INSTALLATION_TYPE_ID]: {
        order: 1,
        name: COMMON_SPACE_INPUTS.ELECTRIC_INSTALLATION_TYPE_ID,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.ELECTRIC_INSTALLATION_TYPE_ID}`,
        tooltip: null,
        placeholder: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.ELECTRIC_INSTALLATION_TYPE_ID}.placeholder`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" },
        answers: [
            { value: 1, description: "label.monophasic" },
            { value: 2, description: "label.threephasic" },
            { value: 3, description: "label.dontKnow" },
        ],
    },
}

export const ADD_COMMON_SPACE_QUESTIONS = {
    [COMMON_SPACE_INPUTS.DESCRIPTION]: {
        order: 1,
        name: COMMON_SPACE_INPUTS.DESCRIPTION,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.DESCRIPTION}`,
        tooltip: null,
        placeholder: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.DESCRIPTION}.placeholder`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.CONTRACTED_POWER_ID]: {
        order: 2,
        name: COMMON_SPACE_INPUTS.CONTRACTED_POWER_ID,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_POWER_ID}`,
        tooltip: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_POWER_ID}.tooltip`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.CONTRACTED_POWER_TARIFF]: {
        order: 3,
        name: COMMON_SPACE_INPUTS.CONTRACTED_POWER_TARIFF,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_POWER_TARIFF}`,
        tooltip: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_POWER_TARIFF}.tooltip`,
        placeholder: `label.value`,
        disabled: false,
        visible: true,
        validation: null
    },
    [COMMON_SPACE_INPUTS.HOURLY_OPTION_ID]: {
        order: 4,
        name: COMMON_SPACE_INPUTS.HOURLY_OPTION_ID,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.HOURLY_OPTION_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.CONTRACTED_ENERGY_TARIFF]: {
        order: 5,
        name: COMMON_SPACE_INPUTS.CONTRACTED_ENERGY_TARIFF,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_ENERGY_TARIFF}`,
        tooltip: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_ENERGY_TARIFF}.tooltip`,
        placeholder: `label.value`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.OPTION_ID]: {
        order: 6,
        name: COMMON_SPACE_INPUTS.OPTION_ID,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.OPTION_ID}`,
        disabled: false,
        visible: true,
        options: [
            {
                label: `page.spv.commonSpace.${COMMON_SPACE_INPUTS.OPTION_ID}.label.option1`,
                value: 1
            },
            {
                label: `page.spv.commonSpace.${COMMON_SPACE_INPUTS.OPTION_ID}.label.option2`,
                value: 2
            },
            {
                label: `page.spv.commonSpace.${COMMON_SPACE_INPUTS.OPTION_ID}.label.option3`,
                value: 3
            },
        ],
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.MONTHLY_INVOICE_BY_HOURLY_PERIOD]: {
        order: 7,
        name: COMMON_SPACE_INPUTS.MONTHLY_INVOICE_BY_HOURLY_PERIOD,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.MONTHLY_INVOICE_BY_HOURLY_PERIOD}`,
        placeholder: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.MONTHLY_INVOICE_BY_HOURLY_PERIOD}.placeholder`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    }
};

export const EDIT_COMMON_SPACE_QUESTIONS = {
    [COMMON_SPACE_INPUTS.DESCRIPTION]: {
        order: 1,
        name: COMMON_SPACE_INPUTS.DESCRIPTION,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.DESCRIPTION}`,
        tooltip: null,
        placeholder: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.DESCRIPTION}.placeholder`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.CONTRACTED_POWER_ID]: {
        order: 2,
        name: COMMON_SPACE_INPUTS.CONTRACTED_POWER_ID,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_POWER_ID}`,
        tooltip: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_POWER_ID}.tooltip`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.CONTRACTED_POWER_TARIFF]: {
        order: 3,
        name: COMMON_SPACE_INPUTS.CONTRACTED_POWER_TARIFF,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_POWER_TARIFF}`,
        tooltip: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_POWER_TARIFF}.tooltip`,
        placeholder: `label.value`,
        disabled: false,
        visible: true,
        validation: null
    },
    [COMMON_SPACE_INPUTS.HOURLY_OPTION_ID]: {
        order: 4,
        name: COMMON_SPACE_INPUTS.HOURLY_OPTION_ID,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.HOURLY_OPTION_ID}`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.CONTRACTED_ENERGY_TARIFF]: {
        order: 5,
        name: COMMON_SPACE_INPUTS.CONTRACTED_ENERGY_TARIFF,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_ENERGY_TARIFF}`,
        tooltip: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_ENERGY_TARIFF}.tooltip`,
        placeholder: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.CONTRACTED_ENERGY_TARIFF}.placeholder`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.OPTION_ID]: {
        order: 6,
        name: COMMON_SPACE_INPUTS.OPTION_ID,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.OPTION_ID}`,
        disabled: false,
        visible: true,
        options: [
            {
                label: `page.spv.commonSpace.${COMMON_SPACE_INPUTS.OPTION_ID}.label.option1`,
                value: 1
            },
            {
                label: `page.spv.commonSpace.${COMMON_SPACE_INPUTS.OPTION_ID}.label.option2`,
                value: 2
            },
            {
                label: `page.spv.commonSpace.${COMMON_SPACE_INPUTS.OPTION_ID}.label.option3`,
                value: 3
            },
        ],
        validation: { required: "yup.message.required" }
    },
    [COMMON_SPACE_INPUTS.MONTHLY_INVOICE_BY_HOURLY_PERIOD]: {
        order: 7,
        name: COMMON_SPACE_INPUTS.MONTHLY_INVOICE_BY_HOURLY_PERIOD,
        label: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.MONTHLY_INVOICE_BY_HOURLY_PERIOD}`,
        placeholder: `page.spv.commonSpace.question.${COMMON_SPACE_INPUTS.MONTHLY_INVOICE_BY_HOURLY_PERIOD}.placeholder`,
        disabled: false,
        visible: true,
        validation: { required: "yup.message.required" }
    }
};
//#endregion COMMON SPACE FORM

//#region CHARACTERIZATION SPACE QUESTIONS
export const CHARACTERIZATION_SPACE_QUESTIONS = {
    [SPV_INPUT_NAMES.JOINT_OWNERS_NUMBER]: {
        order: 1,
        name: SPV_INPUT_NAMES.JOINT_OWNERS_NUMBER,
        label: `page.spv.characterizationSpace.question.${SPV_INPUT_NAMES.JOINT_OWNERS_NUMBER}`,
        placeholder: `label.enterValueHere`,
        disabled: false,
        visible: true,
        validation: {
            required: "yup.message.required",
            min: {
                value: 0,
                message: 'yup.message.number.min'
            }
        }
    },
    [SPV_INPUT_NAMES.JOINT_OWNERS_SMART_METER]: {
        order: 2,
        name: SPV_INPUT_NAMES.JOINT_OWNERS_SMART_METER,
        label: `page.spv.characterizationSpace.question.${SPV_INPUT_NAMES.JOINT_OWNERS_SMART_METER}`,
        placeholder: `label.enterValueHere`,
        disabled: false,
        visible: true,
        validation: null
    },
    [SPV_INPUT_NAMES.JOINT_OWNERS_INFO]: {
        order: 3,
        name: SPV_INPUT_NAMES.JOINT_OWNERS_INFO,
        label: `page.spv.characterizationSpace.question.${SPV_INPUT_NAMES.JOINT_OWNERS_INFO}`,
        disabled: false,
        visible: true,
        validation: null
    },
}
//#endregion CHARACTERIZATION SPACE QUESTIONS




export const QUESTIONS_CONTACTFORM = {
    [USER_GROUP_IDS.STANDARD]: {//mini-user
        [SPACE_TYPE_IDS.HOUSING]: {
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                // [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION_SMART]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                // [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.LECS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.BATTERY]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                // [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SOLAR_AQS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                // [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
        },
        [SPACE_TYPE_IDS.CONDOMINIUM]: {
            [SPV_BUSINESS_MODEL_IDS.LECS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION_CONDOMINIUM]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.CPE]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CPE],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            }
        },
        [SPACE_TYPE_IDS.NEIGHBOUR]: {
            [SPV_BUSINESS_MODEL_IDS.LECS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            }
        },
        [SPACE_TYPE_IDS.HOUSING_PANELS]: {
            [SPV_BUSINESS_MODEL_IDS.BATTERY]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION_HOUSING_PANELS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SOLAR_AQS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.BATTERY_HOUSING_PANELS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SOLAR_AQS_HOUSING_PANELS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
        },
        [SPACE_TYPE_IDS.APARTMENT]: {
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION_APARTMENT]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.BATTERY_APARTMENT]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
        }
    },
    [USER_GROUP_IDS.MANAGER]: {//super-user
        [SPACE_TYPE_IDS.HOUSING]: {
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                // [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION_SMART]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                // [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.LECS]: {//FIXME: CONTACT_ADDRESS_RESIDENTIAL CONTACT_IDENTITY_NUMBER add to form questions and input?
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_IDENTITY_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_IDENTITY_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.BATTERY]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                // [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SOLAR_AQS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                // [SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.ELECTRIC_INSTALLATION_TYPE_ID],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
        },
        [SPACE_TYPE_IDS.CONDOMINIUM]: {
            [SPV_BUSINESS_MODEL_IDS.LECS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION_CONDOMINIUM]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.CPE]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CPE],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            }
        },
        [SPACE_TYPE_IDS.NEIGHBOUR]: {
            [SPV_BUSINESS_MODEL_IDS.LECS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_IDENTITY_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_IDENTITY_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_ADDRESS_RESIDENTIAL],
                [SPV_INPUT_NAMES.CONTACT_NEIGHBORHOOD_ADDRESS]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NEIGHBORHOOD_ADDRESS],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            }
        },
        [SPACE_TYPE_IDS.HOUSING_PANELS]: {
            [SPV_BUSINESS_MODEL_IDS.BATTERY]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION_HOUSING_PANELS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SOLAR_AQS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.BATTERY_HOUSING_PANELS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.SOLAR_AQS_HOUSING_PANELS]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
        },
        [SPACE_TYPE_IDS.APARTMENT]: {
            [SPV_BUSINESS_MODEL_IDS.SELF_CONSUMPTION_APARTMENT]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
            [SPV_BUSINESS_MODEL_IDS.BATTERY_APARTMENT]: {
                [SPV_INPUT_NAMES.CONTACT_NAME]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_NAME],
                [SPV_INPUT_NAMES.CONTACT_TAX_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_TAX_NUMBER],
                [SPV_INPUT_NAMES.CONTACT_EMAIL]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_EMAIL],
                [SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.CONTACT_PHONE_NUMBER],
                [SPV_INPUT_NAMES.PERSONAL_DATA_YIELD]: SPV_QUESTION_CONTACT_FORM[SPV_INPUT_NAMES.PERSONAL_DATA_YIELD],
            },
        }
    }
}

//#region AQS
export const AQS_EQUIPMENTS_NAMES = {
    SOLAR_PV: 'solar_pv',
    AQS: 'aqs'
}
export const QUESTIONS_AQS_BUNDLE = {
    [SPV_INPUT_NAMES.AQS_SELECTED]: {
        order: 1,
        name: SPV_INPUT_NAMES.AQS_SELECTED,
        image: require("assets/images/products/spv/aqs_bundle.png"),
        title: "page.spv.simulation.bundle-questions.aqs_selected.title",
        description: [
            "page.spv.simulation.bundle-questions.aqs_selected.description",
        ],
        visible: true,
        disabled: false
    },
    [SPV_INPUT_NAMES.NUM_OCCUPANTS]: {
        order: 2,
        name: SPV_INPUT_NAMES.NUM_OCCUPANTS,
        label: "page.spv.simulation.bundle-questions.num_occupants",
        tooltip: "page.spv.simulation.bundle-questions.num_occupants.tooltip",
        visible: true,
        disabled: false,
        rules: validate([VALIDATION_RULES.REQUIRED, VALIDATION_RULES.MAX], { max: 10 })
    },
    [SPV_INPUT_NAMES.HAS_WATER_HEATING_EQUIPMENT]: {
        order: 3,
        name: SPV_INPUT_NAMES.HAS_WATER_HEATING_EQUIPMENT,
        label: "page.spv.simulation.bundle-questions.has_water_heating_equipment",
        visible: true,
        disabled: false,
        rules: validate([[VALIDATION_RULES.REQUIRED]]),
        options: [
            { value: true, label: 'label.yes' },
            { value: false, label: 'label.no' },
        ]
    },
    [SPV_INPUT_NAMES.OLD_EQUIPMENT_TYPE_ID]: {
        order: 4,
        name: SPV_INPUT_NAMES.OLD_EQUIPMENT_TYPE_ID,
        label: "page.spv.simulation.bundle-questions.old_equipment_type_id",
        visible: true,
        disabled: false,
        rules: { required: "yup.message.required" },
    },
    [SPV_INPUT_NAMES.ENERGY_SOURCE_ID]: {
        order: 5,
        name: SPV_INPUT_NAMES.ENERGY_SOURCE_ID,
        label: "page.spv.simulation.bundle-questions.energy_source_id",
        visible: true,
        disabled: false,
        rules: { required: "yup.message.required" },
    }
}
export const AQS_PRODUCT_CARDS = [
    {
        order: 1,
        name: AQS_EQUIPMENTS_NAMES.SOLAR_PV,
        showCheckBox: false,
        icon: <SolarPVIcon />,
        label: 'page.spv.simulation.bundle-products.solarPV',
        isCompleted: true,
        buttonLabel: 'label.open',
        buttonAction: 'go back'
    },
    {
        order: 2,
        name: AQS_EQUIPMENTS_NAMES.AQS,
        showCheckBox: true,
        icon: <AQSIcon />,
        label: 'page.spv.simulation.bundle-products.AQS',
        isCompleted: false,
        buttonLabel: 'label.sizing',
        buttonAction: 'open dialog'
    }
]
//#endregion
